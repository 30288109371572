import React from "react";
import "./misionVisionValores.css";
import { misionVisionValores } from "../../portfolio";
import { Fade } from "react-reveal";

function MisionVisionValores(props) {
  const theme = props.theme;
  return (
    <div>
      <div className="skills-header-div">
        <h2 className="skills-heading pretitle" style={{ color: theme.text }}>
          {"¿Por qué?"}
        </h2>
        <Fade bottom duration={2000} distance="20px">
          <h1 className="skills-header" style={{ color: theme.text }}>
          Mision, Visión y Valores
          </h1>
        </Fade>
      </div>
      <div class="container-services-main">
        {

          misionVisionValores.map((aptitude) => {
            return (

              <Fade left duration={2000}>
                <div class="container-services" id="container-servs">
                  <div>
                    <img alt="" src={aptitude.img} />
                    <div>
                      <h4>{aptitude.title}</h4>
                      <h5>{aptitude.subtitle}</h5>
                    </div>
                  </div>
                  <p style={{ color: theme.secondaryText }}>{aptitude.text}</p>
                </div>
              </Fade>
            );
          })}
      </div >
    </div >
  )
}

export default MisionVisionValores;
