import React, { useEffect } from "react";

const EmojiAnimation = () => {
  const myEmojis = [
    "🙃",
    "😉",
    "😌",
    "😍",
    "🥰",
    "😘",
    "😍",
    "🥰",
    "😘",
    "😗",
    "😙",
    "😚",
    "😋",
    "😛",
    "😝",
    "😜",
    "🤪",
    "🤨",
    "🧐",
    "🤓",
    "😎",
    "🤩",
    "🥳",
    "😏",
    "😒",
    "😞",
    "😔",
    "😟",
  ];

  const urlAnimated = () => {
    window.location.hash =
      "home/" + myEmojis[Math.floor(Date.now() % myEmojis.length)];
    setTimeout(urlAnimated, 18);
  };

  useEffect(() => {
    urlAnimated();
    // La función urlAnimated se ejecutará cuando el componente se monte
  }, []); // El array vacío [] asegura que el efecto solo se ejecute una vez al montar el componente

  return <></>;
};

export default EmojiAnimation;
