import React from "react";
import "./OurTeam.css";
import { Fade } from "react-reveal";
import { OverlayTrigger, Tooltip } from "react-bootstrap";

export default function OurTeam(props) {
  const theme = props.theme;
  return (
    <div className="main" id="team">
      <div className="skills-header-div ">
        <h2 className="skills-heading pretitle" style={{ color: theme.text }}>
          {"Nuestro Equipo"}
        </h2>
        <Fade bottom duration={1000} distance="20px">
          <h1 className="skills-header" style={{ color: theme.text }}>
            Conoce Nuestro Equipo
          </h1>
        </Fade>
      </div>
      <div className="main-container-team">
        <Fade left duration={1000} distance="300px">
          <div className="container-integrant">
            <div className="container-img-integrant">
              <img
                alt="Javier Vidal Icon"
                src="https://cdn-icons-png.flaticon.com/512/3270/3270936.png"
              ></img>
            </div>
            <h2>Javier Vidal</h2>
            <h3>
              Industrial engineer. Efficient processes, optimization, logistics.
            </h3>
            <div className="container-socials-integrant">
              <a
                href="https://www.linkedin.com/in/javier-vidal-18a70b1b7/"
                target={"_blank"}
              >
                <OverlayTrigger
                  placement={"top"}
                  overlay={
                    <Tooltip id={`tooltip-top`}>
                      <strong>{"Linkedin"}</strong>
                    </Tooltip>
                  }
                >
                  <li
                    style={{
                      listStyle: "none",
                      marginTop: "0.75rem",
                      fontSize: "25px",
                      marginLeft: "1rem",
                    }}
                    name={"Linkedin"}
                  >
                    <span
                      className="iconify"
                      data-icon={"simple-icons:linkedin"}
                      data-inline="false"
                    ></span>
                  </li>
                </OverlayTrigger>
              </a>
              <a href="https://wa.me/604934292" target={"_blank"}>
                <OverlayTrigger
                  placement={"top"}
                  overlay={
                    <Tooltip id={`tooltip-top`}>
                      <strong>{"Whatsapp"}</strong>
                    </Tooltip>
                  }
                >
                  <li
                    style={{
                      listStyle: "none",
                      marginTop: "0.75rem",
                      fontSize: "25px",
                      marginLeft: "1rem",
                    }}
                    name={"Whatsapp"}
                  >
                    <span
                      className="iconify"
                      data-icon={"simple-icons:whatsapp"}
                      data-inline="false"
                    ></span>
                  </li>
                </OverlayTrigger>
              </a>
            </div>
          </div>
        </Fade>
        <Fade right duration={1000} distance="300px">
          <div className="container-integrant">
            <div className="container-img-integrant">
              <img
                alt=""
                src="https://cdn-icons-png.flaticon.com/512/5024/5024509.png"
              ></img>
            </div>
            <h2>Daniel Burruchaga</h2>
            <h3>Electronics, Full Stack, Artificial Inteligence...</h3>
            <div className="container-socials-integrant">
              <a href="https://buchspro.es" target={"_blank"}>
                <OverlayTrigger
                  placement={"top"}
                  overlay={
                    <Tooltip id={`tooltip-top`}>
                      <strong>{"Pagina Personal"}</strong>
                    </Tooltip>
                  }
                >
                  <li
                    style={{
                      listStyle: "none",
                      marginTop: "0.75rem",
                      fontSize: "25px",
                    }}
                    name={"Linkedin"}
                  >
                    <span
                      className="iconify"
                      data-icon={"simple-icons:html5"}
                      data-inline="false"
                    ></span>
                  </li>
                </OverlayTrigger>
              </a>
              <a
                href="https://www.linkedin.com/in/daniel-burruchaga/"
                target={"_blank"}
              >
                <OverlayTrigger
                  placement={"top"}
                  overlay={
                    <Tooltip id={`tooltip-top`}>
                      <strong>{"Linkedin"}</strong>
                    </Tooltip>
                  }
                >
                  <li
                    style={{
                      listStyle: "none",
                      marginTop: "0.75rem",
                      fontSize: "25px",
                      marginLeft: "1rem",
                    }}
                    name={"Linkedin"}
                  >
                    <span
                      className="iconify"
                      data-icon={"simple-icons:linkedin"}
                      data-inline="false"
                    ></span>
                  </li>
                </OverlayTrigger>
              </a>
              <a href="https://wa.me/604934292" target={"_blank"}>
                <OverlayTrigger
                  placement={"top"}
                  overlay={
                    <Tooltip id={`tooltip-top`}>
                      <strong>{"Whatsapp"}</strong>
                    </Tooltip>
                  }
                >
                  <li
                    style={{
                      listStyle: "none",
                      marginTop: "0.75rem",
                      fontSize: "25px",
                      marginLeft: "1rem",
                    }}
                    name={"Whatsapp"}
                  >
                    <span
                      className="iconify"
                      data-icon={"simple-icons:whatsapp"}
                      data-inline="false"
                    ></span>
                  </li>
                </OverlayTrigger>
              </a>
            </div>
          </div>
        </Fade>
      </div>
    </div>
  );
}
