import React from "react";
import Header from "../../components/header/Header";
import Greeting from "../../containers/greeting/Greeting";
import Skills from "../../containers/skills/Skills";
import Contact from "../../containers/contact/Contact";
import OurTeam from "../../containers/ourteam/OurTeam";
import Footer from "../../components/footer/Footer";
import CookiePolicy from "../../components/CookiesPolicy";
import EmojiAnimation from "../../components/EmojiUrl/EmojiUrl.component";
import MisionVisionValores from "../../containers/misionVisionValores/MisionVisionValores";

function Home(props) {
  const nebulaheroAnimation = {
    "0%": { backgroundPosition: "0 0" },
    "100%": { backgroundPosition: "100% 1110" },
  };

  const herobgcolorStyle = {
    position: "absolute",
    top: 0,
    left: 0,
    width: "100%",
    height: "100%",
    background: "linear-gradient(to right, #DF282E, #EF2A77, #4A3CB9)",
    animationName: nebulaheroAnimation,
    animationDuration: "20s",
    animationIterationCount: "infinite",
    animationTimingFunction: "ease-in-out",
  };

  return (
    <div style={herobgcolorStyle} className="herobgcolor" id="home">
      <Header theme={props.theme} setTheme={props.setTheme} />
      <Greeting theme={props.theme} />
      <Skills theme={props.theme} />
      <MisionVisionValores theme={props.theme} />
      <Contact theme={props.theme} />
      <OurTeam theme={props.theme} />
      <Footer theme={props.theme} />
      <CookiePolicy />
      <EmojiAnimation />
    </div>
  );
}

export default Home;
