import React from "react";
import "./Greeting.css";
import SocialMedia from "../../components/socialMedia/SocialMedia";
import { greeting } from "../../portfolio";
import { Fade } from "react-reveal";
import { useHistory } from "react-router-dom";
import FeelingProud from "./FeelingProud";
import { style } from "glamor";
import { handleClickScroll } from "../../utils/handleClickScroll";
export default function Greeting(props) {
  const theme = props.theme;
  const history = useHistory();

  return (
    <Fade bottom duration={2000} distance="40px">
      <div className="greet-main" id="greeting">
        <div className="greeting-main">
          <div className="greeting-text-div">
            <h1 className="greeting-text">{greeting.title}</h1>
            <h1 className="greeting-text">{greeting.title2}</h1>
            <button
              className="button"
              style={{background:"rgb(0,0,0,0.4)"}}
              onClick={() => {
                handleClickScroll("works");
              }}
            >
              {greeting.button}
            </button>
          </div>
        </div>
      </div>
    </Fade>
  );
}
